/*
 * @Descripttion:
 * @FilePath: /azg/src/router/index.js
 * @Author: 张兴业
 * @Date: 2020-10-23 10:45:24
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-22 20:34:54
 */
import Vue from "vue";
import VueRouter from "vue-router";
// import Index from "../views/index";
import MewIndex from "../views/newIndex";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MewIndex",
    component: MewIndex,
  },

  {
    path: "/login",
    name: "login",
    component: () => import("../views/login"),
    beforeEnter: (to, from, next) => {
      to.params.id = from.path;
      next();
    },
  },
  // pop旗舰店入住申请
  {
    path: "/applyIntr",
    name: "applyIntr",
    component: () => import("../views/applyContact/applyIntr"),
  },
  {
    path: "/applyPop",
    name: "applyPop",
    component: () => import("../views/applyContact/applyPop"),
  },
  {
    path: "/applyagent",
    name: "applyagent",
    component: () => import("../views/applyContact/applyAgent"),
  },
  {
    path: "/applyJoinUs",
    name: "applyJoinUs",
    component: () => import("../views/applyContact/applyJoinUs"),
  },
  {
    path: "/applyOversea",
    name: "applyOversea",
    component: () => import("../views/applyContact/applyOversea"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../views/userCenter/changePassword"),
  },
  {
    path: "/noticeMsg",
    name: "noticeMsg",
    component: () => import("../views/userCenter/noticeMsg"),
  },
  {
    path: "/applyAfter",
    name: "applyAfter",
    component: () => import("../views/applyContact/applyAfter"),
  },

  // 常见问题
  {
    path: "/question/detail",
    name: "question.detail",
    component: () => import("../views/question/detail"),
  },
  //招聘
  {
    path: "/zhaopin/xiaozhao",
    name: "zhaopin.xiaozhao",
    component: () => import("../views/zhaopin/xiaozhao"),
  },

  // 地推宝推荐扫码注册
  {
    path: "/ditui/register",
    name: "ditui.register",
    meta: {
      title: "注册",
    },
    component: () => import("../views/ditui/register"),
  },

  // 地推宝二次认证信息确认
  {
    path: "/ditui/register/confirm",
    name: "ditui.register.confirm",
    meta: {
      title: "信息确认",
    },
    component: () => import("../views/ditui/confirm"),
  },

  // 代办校验
  {
    path: "/daiban/check",
    name: "daiban.check",
    meta: {
      title: "校验手机号",
    },
    component: () => import("../views/daiban/check"),
  },

  // 代办信息确认
  {
    path: "/daiban/confirm",
    name: "daiban.confirm",
    meta: {
      title: "确认信息",
    },
    component: () => import("../views/daiban/confirm"),
  },

  // 以下为兴业的老路由代码
  {
    path: "/register/store",
    name: "Reginster.Store",
    component: () => import("../views/register/store"),
  },
  {
    path: "/register/distributor",
    name: "Reginster.Distributor",
    component: () => import("../views/register/distributor"),
  },
  {
    path: "/register/result",
    name: "Reginster.Result",
    component: () => import("../views/register/result"),
  },
  {
    path: "/register/agreement",
    name: "Reginster.Agreement",
    component: () => import("../views/register/agreement"),
  },
  {
    path: "/consumer/notification",
    name: "Consumer.Notification",
    component: () => import("../views/commons/noticebook"),
  },
  {
    path: "/download/app/retail",
    name: "Download.Store.Retail.App",
    component: () => import("../views/download/store/retail"),
  },
  {
    path: "/download/app/distributor",
    name: "Download.Store.Distributor.App",
    component: () => import("../views/download/store/distributor"),
  },
  {
    path: "/download/app/axg",
    name: "Download.Store.Axg.App",
    component: () => import("../views/download/store/axg"),
  },
  {
    path: "/download/app/axt",
    name: "Download.Store.Axt.App",
    component: () => import("../views/download/store/axt"),
  },
  // 兴业的老路由代码结束
  // 公司介绍
  {
    path: "/companyintr",
    name: "CompanyIntr",
    component: () => import("../views/companyintr"),
  },
  //  az海外
  {
    path: "/azabord",
    name: "AzAbord",
    component: () => import("../views/azabord"),
  },
  {
    path: "/demo/video",
    name: "Demo.Video",
    component: () => import("../views/demo/video"),
  },
  // app协议页面
  {
    path: "/xieyi",
    name: "Xieyi",
    component: () => import("../views/xieyi"),
  },
];
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    // 始终滚动到顶部
    return { x: 0, y: 0 };
  },
});

export default router;
