var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ height_bar: !_vm.showBar }},[_c('div',{staticClass:"bar_pos1",style:(_vm.bgStyle1)},[_c('div',{staticClass:"left_logo"},[_c('img',{staticClass:"bar_logo",attrs:{"src":_vm.opacityStyle1.opacity === 1 ? _vm.logoBg : _vm.logo,"alt":""},on:{"click":_vm.goIndex}}),_c('div',{staticClass:"bar_list"},_vm._l((_vm.$t('barList')),function(item,index){return _c('div',{key:index},[(index != 4)?_c('div',{staticClass:"list_item font16",class:{
              list_item_bor: _vm.curIndex === index,
              list_item_bgcolo: _vm.opacityStyle1.opacity === 1
            }},[_c('a',{staticClass:"font16 font400w list_a",attrs:{"href":`#${index + 1}`},on:{"click":function($event){return _vm.changePosition(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]):_vm._e()])}),0),_c('div',{staticClass:"right_changelanguages"}),_c('div',{staticClass:"right_changelanguages font16 font400w",class:_vm.opacityStyle1.opacity === 1 ? 'clb07' : 'clf100',on:{"click":_vm.changelanguages}},[_c('span',{class:_vm.$t('languages') == '中文'
              ? ''
              : _vm.opacityStyle1.opacity === 1
              ? 'clb03'
              : 'clf08'},[_vm._v("中")]),_c('span',{class:_vm.$t('languages') == 'EN'
              ? ''
              : _vm.opacityStyle1.opacity === 1
              ? 'clb03'
              : 'clf08'},[_vm._v("/EN")])]),(!_vm.token)?_c('div',{staticClass:"right_login font16 font500w",class:_vm.opacityStyle1.opacity === 1 ? 'clb100' : 'clf100',on:{"click":_vm.goLogon}},[_vm._v(" "+_vm._s(_vm.$t("loginData.login"))+" ")]):_vm._e(),(_vm.token)?_c('div',{staticClass:"right_login font16 clb100 font500w"},[_c('el-dropdown',[_c('div',{staticClass:"login_top"},[_c('span',{staticClass:"el-dropdown-link",class:_vm.opacityStyle1.opacity === 1 ? 'clb100' : 'clf100'},[_vm._v(" "+_vm._s(_vm.phone)+" ")]),_c('img',{staticClass:"login_icon",attrs:{"src":_vm.opacityStyle1.opacity === 1
                  ? require('@/assets/img/index/Group 6823@2x.png')
                  : require('@/assets/img/index/Group 6823_2@2x.png'),"alt":""}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.$t('TopLoginData')),function(item,index){return _c('el-dropdown-item',{key:index,nativeOn:{"click":function($event){return _vm.goNext(item.url)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }