<!--
 * @Author: your name
 * @Date: 2021-02-01 17:08:26
 * @LastEditTime: 2021-02-01 18:50:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/publicComponents/WapBottom.vue
-->
<template>
  <div>
    <div :style="bgStyle1" class="wap_bar_con">
      <img
        :src="opacityStyle1.opacity === 1 ? leftLogo : leftLogo1"
        alt=""
        class="bar_logo_l"
        @click="goIndex"
      />
      <!-- rightLogo -->
      <img
        :src="opacityStyle1.opacity === 1 ? rightLogo : rightLogo1"
        alt=""
        class="bar_logo"
        @click="drawer = true"
      />
    </div>
    <el-drawer :visible.sync="drawer" :with-header="false">
      <div class="draw_list">
        <img
          src="@/assets/img/index/Close _ 24 _ Outline@2x.png"
          class="bar_logo"
          alt=""
          @click="drawer = false"
        />
        <div v-for="(item, index) in $t('barList')" :key="index">
          <div
            class="wapfont16 draw_item"
            @click="changePosition(index)"
            v-if="index != 4"
          >
            <a :href="`#${index + 1}`" @click="changePosition(index)">
              {{ item.name }}
            </a>
          </div>
        </div>
        <!--底部信息 -->
        <div class="bar_bottom">
          <div class="right_changelanguages wapfont16 clb07 font400w"></div>
          <div
            @click="changelanguages"
            class="right_changelanguages wapfont16 clb07 font400w"
          >
            <span :class="$t('languages') == '中文' ? '' : 'clb03'">中</span>
            <span :class="$t('languages') == 'EN' ? '' : 'clb03'">/EN</span>
          </div>
          <div>
            <!-- 未登录 -->
            <div
              v-if="!token"
              class="wapfont16 clb100 bar_login"
              @click="goLogon"
            >
              <!-- 登录 -->
              {{ $t("loginData.login") }}
            </div>
            <!-- 已登录 -->
            <div v-if="token" class="right_login wapfont16 clb100 font500w">
              <el-dropdown trigger="click">
                <img
                  src="@/assets/img/index/Group 6823@2x.png"
                  alt=""
                  class="login_icon"
                />
                <!-- </div> -->
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in $t('TopLoginData')"
                    :key="index"
                    @click.native="goNext(item.url)"
                  >
                    {{ item.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  props: {
    bgStyle: {
      type: Object,
      default() {
        return {
          backgroundColor: "rgba(255,255,255," + 1 + ")"
        };
      }
    },
    opacityStyle: {
      type: Object,
      default() {
        return {
          opacity: 1
        };
      }
    },
    showBar: {
      type: Boolean,
      default: false
    },
    curScroll: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      bgStyle1: {
        backgroundColor: "rgba(255,255,255," + 1 + ")"
      },
      opacityStyle1: {
        opacity: 1
      },
      drawer: false,
      curIndex: 0,
      token: localStorage.getItem("AZG_TOKEN"),
      phone: localStorage.getItem("AZG_USER"),
      leftLogo: require("@/assets/img/index/index_homel1.jpg"),
      leftLogo1: require("@/assets/img/index/azg_left1@2x.png"),
      rightLogo: require("@/assets/img/index/index_menu_r1.jpg"),
      rightLogo1: require("@/assets/img/index/bar_right1@2x.png")
    };
  },
  mounted() {
    this.curIndex = this.curScroll;
    if (this.showBar) {
      this.bgStyle1 = JSON.parse(JSON.stringify(this.bgStyle));
      this.opacityStyle1 = JSON.parse(JSON.stringify(this.bgStyle));
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let opacity = top / 100;
      opacity = opacity >= 1 ? 1 : opacity;
      this.bgStyle1 = { backgroundColor: "rgba(255,255,255," + opacity + ")" };
      this.opacityStyle1 = { opacity };
    },
    changePosition(index) {
      this.curIndex = index;
      this.drawer = false;
      this.$router.push("/#" + (index + 1));
      if (index <= 7) {
        setTimeout(() => {
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          window.scrollTo(0, top - 100);
        }, 0);
      }
      this.$emit("curIndex", this.curIndex);
    },
    // 跳转登录
    goLogon() {
      this.curIndex = 0;
      this.$router.push("/login");
    },
    changelanguages() {
      this.$i18n.locale == "zh"
        ? (this.$i18n.locale = "en")
        : (this.$i18n.locale = "zh");
      localStorage.setItem("AZG_LANGUAGE", this.$i18n.locale);
    },
    goNext(url) {
      if (!url) {
        localStorage.removeItem("AZG_TOKEN");
        localStorage.removeItem("AZG_USER");
        window.location.replace("/");
        this.$message({
          message: "退出登录成功",
          type: "none",
          customClass: "zidingyiel_message"
        });
        return;
      }
      this.$router.push(url);
    },
    goIndex() {
      this.curIndex = 0;
      this.$router.push("/");
      window.scrollTo(0, 0);
    }
  }
};
</script>
<style lang="less" scoped>
a:link {
  text-decoration: none;
  // color: #fff;
}
a:visited {
  text-decoration: none;
  // color: #fff;
}
a:hover {
  text-decoration: none;
  // color: #fff;
}
a:active {
  text-decoration: none;
  // font-size: 14px;
  // color: #fff;
}
.draw_item > a:link {
  color: rgba(0, 0, 0, 0.87) !important;
}
.draw_item > a:visited {
  color: rgba(0, 0, 0, 0.87) !important;
}
.draw_item > a:hover {
  color: rgba(0, 0, 0, 0.87) !important;
}
.draw_item > a:active {
  color: rgba(0, 0, 0, 0.87) !important;
}
.wap_bar_con {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  // background: #fff;
  padding: calc(100vw * 1.2 / 37.5);
  display: flex;
  justify-content: space-between;
  .bar_logo_l {
    cursor: pointer;
    width: calc(100vw * 2.4 / 37.5);
    height: calc(100vw * 2.8 / 37.5);
  }
  .bar_logo {
    cursor: pointer;
    width: calc(100vw * 2.4 / 37.5);
    height: calc(100vw * 2.4 / 37.5);
  }
}
.draw_list {
  margin: calc(100vw * 1.3 / 37.5) calc(100vw * 1.5 / 37.5) 0
    calc(100vw * 5 / 37.5);
  img {
    width: calc(100vw * 2.4 / 37.5);
  }
  .draw_item {
    margin-top: calc(100vw * 3.6 / 37.5);
    word-wrap: nowrap;
    text-align: left;
    cursor: pointer;
  }
}
::v-deep .rtl {
  width: calc(100vw * 24.5 / 37.5) !important;
  text-align: right;
}
.right_changelanguages {
  cursor: pointer;
}
.right_login {
  cursor: pointer;
  margin-left: 30px;
}
.bar_bottom {
  position: absolute;
  bottom: calc(100vw * 3.6 / 37.5);
  left: calc(100vw * 3.6 / 37.5);
  right: calc(100vw * 3.6 / 37.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin: 0 calc(100vw * 3.6 / 37.5);
}
.bar_login {
  cursor: pointer;
}
.login_icon {
  width: calc(100vw * 2.4 / 192);
  min-width: 20px;
  // margin-left: calc(100vw * 1 / 192);
}
</style>
